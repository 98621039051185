import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Grid, Step, StepLabel, Stepper, styled } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@openx/components/core/lib/Button/Button';

import { PageHeader } from 'components/PageHeader/PageHeader';
import { Result, sentGoogleResult } from 'modules/googleIntegration';
import { useCta } from 'state/ctaProvider';

import { ConfirmationStep } from './ConfirmationStep';
import { DetailsStep } from './DetailsStep';
import { PromoBox } from './PromoBox';

const StyledBackButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
  color: ${({ theme }) => theme.palette.common.white};
`;

const StyledBackArrowIcon = styled(ArrowBackIosIcon)`
  font-size: 10px;
  margin-right: ${({ theme }) => theme.spacing(1.5)};
`;

const StyledPromoBoxContainerDiv = styled('div')`
  margin-top: 75px;
  margin-left: 50px;
`;

const StyledSteppedContainerDiv = styled('div')`
  margin: auto;
  width: 325px;
`;

interface StepI {
  key: string;
  label: string;
}

export function LandingPage(): JSX.Element {
  const { t } = useTranslation();
  const {
    state: { currentStep },
  } = useCta();
  const CurrentStep = [DetailsStep, ConfirmationStep][currentStep];
  const promoBoxList = [
    [
      {
        subtitle: t('OpenX Ad Exchange'),
        text: t(
          'OpenX is a pioneering leader in advertising technology, helping create a world where the open web thrives.'
        ),
      },
      {
        subtitle: t('Powerful Monetization'),
        text: t(
          'The OpenX Ad Exchange comes equipped with built in demand from 100% of the top 100 AdAge Advertisers, accessing all screens and ad formats.'
        ),
      },
    ],
  ][currentStep];

  const steps: StepI[] = useMemo(
    () => [
      { key: 'details-step', label: t('Details') },
      { key: 'confirmation-step', label: t('Confirmation') },
    ],
    [t]
  );

  const renderSteps = useMemo((): JSX.Element[] => {
    return steps.map(({ key, label }: StepI, step: number) => {
      const isActive = currentStep === step;
      const isCompleted = currentStep > step;
      const dataTest = key + (isActive ? '-active' : isCompleted ? '-completed' : '');

      return (
        <Step key={key} completed={isCompleted} data-test={dataTest}>
          <StepLabel>{label}</StepLabel>
        </Step>
      );
    });
  }, [currentStep, steps]);

  const renderStepper = useCallback(
    () => (
      <StyledSteppedContainerDiv>
        <Stepper nonLinear variant="outlined" activeStep={currentStep}>
          {renderSteps}
        </Stepper>
      </StyledSteppedContainerDiv>
    ),
    [currentStep, renderSteps]
  );

  const backToGoogle = useCallback(() => {
    sentGoogleResult(Result.CANCEL);
  }, []);

  return (
    <PageHeader title="" titlePrefix={t('Google OB Online Onboarding')} render={renderStepper}>
      <Grid container>
        <Grid item xs={3}>
          {!currentStep && (
            <StyledBackButton variant="text" data-test="back-to-google-button" onClick={backToGoogle}>
              <StyledBackArrowIcon />
              {t('Back to Google')}
            </StyledBackButton>
          )}
        </Grid>
        <Grid item xs={6}>
          <CurrentStep />
        </Grid>
        <Grid item xs={3}>
          <StyledPromoBoxContainerDiv>
            <PromoBox promoBoxList={promoBoxList || []} />
          </StyledPromoBoxContainerDiv>
        </Grid>
      </Grid>
    </PageHeader>
  );
}
